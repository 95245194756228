@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  
  .MuiButton-containedPrimary #loading {
    color: white;
    animation: spinner 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  }
  
  .MuiButton-containedPrimary .loading__circle--one {
    opacity: 25%;
  }
  .MuiButton-containedPrimary .loading__circle--two {
    opacity: 75%;
  }