/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.MuiDataGrid-row:nth-child(even){
  background-color: #f4f4f4;
}

.MuiSelect-filled.MuiSelect-filled {
  padding-top: 7px;
}

.MuiButton-containedPrimary {
  background-color: #FF5000 !important;
}